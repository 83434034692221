import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Un material care trebuie parcurs de fiecare pastor, învățător sau lider creștin care vrea să aibă o pregătire teologică adecvată și mesaje biblice relevante pentru auditoriul contemporan.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Tot ce suntem, credem, predicăm sau aplicăm, ca și creștini, avem pretenția că sunt aspecte sau adevăruri fundamentate în Scripturi; această pretenție impune să avem o cunoaștere clară din punct de vedere doctrinar a Bibliei. Cele mai importante învățături (doctrine) biblice pot fi studiate, analizate și asumate prin acest curs de teologie neplictisitor și foarte practic.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Pe parcursul a două serii de 12 lecții, și folosind ca manual de bază cartea „Teologie elementară” de Charles C. Ryrie, acest curs ne va ajuta să creștem în cunoașterea lui Dumnezeu și să putem fi de folos să întărim credința altora prin slujirea noastră.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Teologia este „Învățătura despre Dumnezeu” și este acum și la dispoziția ta ca slujitor !
Citat: „ …este vital pentru un credincios să aibă încredere deplină că Biblia este credibilă și este o înregistrare exactă a revelației lui Dumnezeu. Înainte ca oamenii să poată crede Biblia, ei trebuie să fie convinși că ea este adevărată; înainte ca oamenii să poată asculta Biblia, ei trebuie să fie convinși că ea este Cuvântul cu autoritate a lui Dumnezeu.”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    